<template>
  <div class="cardlist">
    <div class="cardlist-top vertical-center" v-if="reward.award_experience && reward.punch_rewards_num">
      <img src="~assets/images/user/warning.png" alt="" class="cardlist-top-img">
      单条奖励{{ reward.award_experience }}经验值，封顶{{ reward.punch_rewards_num }}条
    </div>
    <div class="cardlist-content">
      <div class="cardlist-item space-between" v-for="(item,index) in cardlist" :key="index" @click="gocardinfo(item.id)">
        <img class="cardlist-item-img" :src="item.icon" />
        <div class="cardlist-item-text">
          <div class="cardlist-item-text-title white-space-2">
            {{item.brief}}
          </div>
          <div class="cardlist-item-text-time vertical-center">
            <img src="~assets/images/user/time.png" alt="" class="cardlist-item-text-time-img">
            {{ item.created_at }}
          </div>
        </div>
      </div>
    </div>
    <div class="cardlist-bottom align-center" @click="gocard($route.query.id)">
      上传笔记
    </div>
  </div>
</template>

<script>
export default {
  name: "cardlist",
  data() {
    return {
      cardlist:[],
      reward:{}
    }
  },
  created() {
    this.getstrip()
    this.getInfo()
    this.$store.commit('M_TITLE', this.$route.query.name)
  },
  mounted() {

  },
  methods: {
    //奖励条数
    getstrip(){
      let url = this.$api.award + '?group_id=' + this.$route.query.id;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
          this.reward = res.data.data
        }
      })
    },
    //跳转打卡
    gocard(id){
      this.$router.push({path: '/user/group/card', query: {id: id}})
    },
    getInfo(){
      let url = this.$api.punchClock + '?group_id=' + this.$route.query.id;
      this.$http.get(url,true).then( res =>{
        if(res.data.success){
          this.cardlist = res.data.data
        }
      })
    },
    //跳转打卡详情
    gocardinfo(id){
      this.$router.push({path:'/user/group/card/info',query: {id: id}})
    }
  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/group/card.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;

</style>